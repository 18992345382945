import React from "react";
import { graphql } from "gatsby";
import { Grid, Image } from "semantic-ui-react";
import Helmet from "react-helmet";
import Markdown from "markdown-to-jsx";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  About,
} from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";

export default class extends React.PureComponent {
  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Franchise</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={<h1>Franchising Opportunities</h1>}
              tagline={``}
              images={["https://fisherman.gumlet.io/public/eggandbird/Franchise-min.jpg"]}
              showMap={false}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={true}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={14}>
              <About
                content={
                  <>
                    <p>
                      Egg N Bird brings together an amazing spread of
                      distinctive tastes, textures and ingredients that break
                      the rules when it comes to your basic egg and chicken
                      sandwiches, from breakfast to 4th meal.​ Interested in
                      becoming part of our growing family and opening your own
                      Egg N Bird location? Fill out the form below and a member
                      of our Franchise Team will contact you.
                    </p>
                  </>
                }
                header={"Franchising Opportunities"}
                centerContent={true}
                headerAs={"h1"}
                withContainer={true}
              />
            </Grid.Column>
            <Grid.Column width={12}>
              <iframe
                frameborder="0"
                width="100%"
                height="760"
                src="https://yogurtland.franconnect.net/fc/extforms/enbleadpage.jsp?refLoc=ifrm"
                title="Lead Web Page - ENB"
              ></iframe>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          galleryImages {
            url
          }
          heroImages {
            url
          }
        }
      }
    }
  }
`;
